
.edition-button {
    border: solid 2px #000000;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    margin-right: 3px;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
}

.edition-button:disabled {
    color: #aaaaaa;
    border-color: #aaaaaa;
}

.enabled-button {
    color: #ffffff;
    border-color: #607d8b;
    background-color: #607d8b;
}

.popover-content {
    margin: 15px;
}

.popover-content-line {
    width: 100%;
    display: flex;
}

.popover-content-button-line {
    margin-top: 10px;
    justify-content: flex-end;
}

.accordion-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 520px;
}

.accordion-line {
    width: 100%;
    min-height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}

.accordion-button-line {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.deleting:hover>circle, .deleting:hover>rect {
    fill-opacity: 0.5;
}

.closure {
    background-image: '/public/png/close_runway_icon_big.png';
    background-size: contain;
    background-repeat: no-repeat;
}