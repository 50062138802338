/* Etat nominal */

.beaconAirport .beaconCircle {
	fill: black;
}

.beaconIaf .beaconCircle, .beaconMf .beaconCircle, .beaconCop .beaconCircle {
	fill:RGB(167, 173, 187);
	stroke:black;
}

.beaconGeo .beaconCircle {
	fill:RGB(167, 173, 187);
	stroke:black;
	pointer-events: none;
}

.beaconLim .beaconCircle {
	fill:white;
	stroke: RGB(112,112,112);
	stroke-width:3;
}

.beaconOtherAirport .beaconCircle {
	fill: RGB(221, 225, 240);
	stroke: black;
	stroke-width: 4;
}

.beaconRtg .beaconCircle {
	fill: white;
	stroke: none;
}

.rtgIcon {
	fill: black;
}

.nameArea {
    opacity: 0.0001;
}

.beaconAirport .name {
	font-size: 14px;
	fill:white;
	pointer-events: none;
	font-family: Roboto;
}

.beaconIaf .name, .beaconMf .name, .beaconGeo .name, .beaconCop .name, .beaconRtg .name {
	font-size: 12px;
	fill: white;
	pointer-events: none;
	font-family: Roboto;
}

.beaconLim .nbVolsLabel, .beaconOtherAirport .nbVolsLabel {
	fill: black;
	font-size:9px;
	text-anchor: "middle";
	pointer-events: none;
	font-family: Roboto;
}

.beaconAirport .cadency {
	font-size: 15px;
	fill: white;
	pointer-events: none;
	font-family: Roboto;
}

.roueCrantee {
	fill: none;
	stroke: RGB(167, 173, 187);
	stroke-width: 1px;
}

.currentTime {
	stroke: RGB(167, 173, 187);
	fill: none;
	stroke-width: 2;
	stroke-dasharray: 2,1;
}


/* Etat sélectionné */

.selected:not(.beaconAirport) .beaconCircle {
	stroke: #1150a1;
    stroke-width: 2;
}

.beaconAirport.selected .beaconCircle {
    fill: #1150a1;
}

.beaconRtg.selected .beaconCircle {
	fill: #9d6a6a;
}

.beaconRtg.selected .rtgIcon {
	fill: white
}

.selected .nameArea {
    opacity: 1;
    fill: #1150a1;
}

.selected .xMarkCircle {
    visibility: visible;
    fill: #1150a1;
}

.selected .xLines {
    visibility: visible;
    stroke:white;
	stroke-width: 2px;
}


/* Etat survolé */

.hovered:not(.beaconAirport) .beaconCircle {
	stroke: #3277d1;
    stroke-width: 2;
}

.beaconAirport.hovered .beaconCircle {
    fill: #3277d1;
}

.hovered .nameArea {
    opacity: 1;
    fill: #3277d1;
}
