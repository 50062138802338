.axisWhite text {
   fill:white;
   font-size:12;
}

.axisWhite line {
    stroke: white;
}

.axisX path {
    opacity:0.01;
}

.capacityLine {
    stroke: #a2a2a2;
    stroke-opacity: 1;
    stroke-width: 1;
    stroke-dasharray: 2,2;
}

.grid path {
    stroke-width: 0;
}

.d3-tip-repartition, .d3-tip-dep-arr, .d3-tip {
    z-index: 999999;
    line-height: 1;
    font-size: smaller;
    font-weight: bold;
    padding: 12px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 2px;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip-repartition:after, .d3-tip:after, .d3-tip-dep-arr:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    content: "\25BC";
    position: absolute;
    left:45%;
}

.d3-tip-repartition.n:after, .d3-tip.n:after, .d3-tip-dep-arr.n:after {
    margin: 0 0 0 0;
    top: 100%; 
    font-size: 8;
    left:45%;
}

.sBar:hover{
    stroke: black ;
    stroke-width: 1;
}
